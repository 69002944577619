import React, { useState } from "react";
import { navigate } from "gatsby";
import { useEffectOnce } from "react-use";
import { toast } from "react-toastify";
import { useLocation } from "@gatsbyjs/reach-router";
import LoadingSpinner from "~/components/LoadingSpinner";
import authBackgroundImage from "~/images/auth-background.jpg";
import CardPage from "~/components/CardPage";
import { useApiClient } from "~/hooks/apiContext";
import { useAuthState } from "~/hooks/authContext";
import Button from "~/components/Button";

function VerifySignUp() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");
  const authToken = searchParams.get("authToken");
  const code = searchParams.get("otpCode");

  const [error, setError] = useState<string | undefined>();

  // Use general state here because will have signed in auth state here for a short moment,
  // causing errors
  const authState = useAuthState();

  const client = useApiClient();
  useEffectOnce(() => {
    if (!authToken || !code) {
      setError("Invalid verify url, missing token or code");
      return;
    }

    (async () => {
      try {
        const response = await client.verifyCode(authToken, code);
        if (authState.type !== "signedOut") {
          return;
        }
        if (!response.emailAddress) {
          throw new Error("Account isn't email based");
        }
        authState.signIn({
          username: response.emailAddress,
          emailAddress: response.emailAddress,
          type: response.userType,
          loginType: response.loginType,
          authToken: response.authToken,
        });
        toast.success("Your email is now verified");
        navigate("/dashboard/?showDownload=1");
      } catch (e) {
        setError(String(e));
      }
    })();
  });

  async function onResendLink() {
    if (!email) {
      setError("No email found in url, can't resend");
      return;
    }

    await client.resendOtpLink({
      emailAddress: email,
    });
    toast.success("Verify email link resent");
  }

  return (
    <CardPage backgroundImage={authBackgroundImage}>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>Verifying email address</h2>
        {error ? (
          <>
            <p>{error}</p>
            <Button
              colour="red"
              size="large"
              variant="outline"
              type="button"
              onClick={onResendLink}
            >
              Send a new verification link
            </Button>
          </>
        ) : (
          <>
            <div>
              Verifying account, please wait... <LoadingSpinner />
            </div>
          </>
        )}
      </div>
    </CardPage>
  );
}

export default VerifySignUp;
